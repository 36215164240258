<template lang="pug">
v-container(fluid)
    v-tabs(v-model='tab', color='brand', light)
        v-tab Rates
        v-tab Markup
        v-tab Supplemental Labor
        v-tab Special Parts
        v-tab Labor Baseline
    v-tabs-items(v-model='tab', light)
        v-tab-item
            v-row
                v-col
                    //- v-select(v-model='selectedState', label='State', :items='states', color='brand')
                    v-data-table(:items='filteredRates', :headers='rateHeaders', disable-pagination, hide-default-footer, height='75vh', dense, fixed-header)
                        template(v-slot:item.name='{item, value}')
                            span {{ getLaborName(item.cost_item) }}
                        template(v-slot:item.description='{item, value}')
                            span {{ getLaborDescription(item.cost_item) }}
                        template(v-slot:item.cost='{item, value}')
                            v-text-field.costInput(type='number', v-model.number='item.cost', prefix='$', @change='updateLaborRate(item)', color='brand')
                        template(v-slot:item.selling_price='{item, value}')
                            span {{ dti.formatCurrency(calculateSellingPrice(item)) }}
                    template(v-if='filteredRates.length === 0')
                        v-row
                            v-col
                                span No labor rates for {{ selectedState }}
                        v-row
                            v-col
                                v-btn.white--text(color='green', @click='showNewRatesModal=true') Add Rates

                        v-overlay(absolute, :value='busy')
                            .text-h6 Please Wait...
                            v-progress-linear(indeterminate)

        v-tab-item
            v-container
                v-row
                    v-col
                        v-card(light, flat)
                            v-card-text
                                v-text-field(label='Overhead', type='number', v-model.number='overhead', suffix='%', @change='updateSettings', color='brand')
                                v-text-field(label='Profit', type='number', v-model.number='profit', suffix='%', @change='updateSettings', color='brand')

                            v-overlay(absolute, :value='busy')
                                .text-h6 Please Wait...
                                v-progress-linear(indeterminate)
        v-tab-item
            v-row
                v-col
                    v-card(light)
                        v-card-text
                            v-data-table(:items='supplementalLabor', :headers='supLaborHeaders', dense, disable-pagination, hide-default-footer, fixed-header, height='80vh')
                                template(v-slot:item.flat_rate='{item, value}')
                                    v-checkbox(v-model='item.flat_rate', color='brand', disabled)
                                template(v-slot:item.rate='{item, value}')
                                    span(v-if='item.flat_rate') {{ item.rate }}%
                                template(v-slot:item.labor_types='{item, value}')
                                    span {{ item.laborTypes.map(type => type.cost_item).join(', ') }}
                                template(v-slot:item.edit='{item, value}')
                                    v-btn(icon, @click='editSupLabor(item)')
                                        v-icon edit
                                template(v-slot:body.append)
                                    tr
                                        td(colspan='6')
                                            v-spacer
                                            v-btn(text, @click='startAddNewLaborSup') Add New Item
                                                v-icon add
                                            v-btn(text, @click='showDisplayOrderDialog') Edit display order
        v-tab-item
            v-row
                v-col
                    SpecialParts
        v-tab-item
            v-row
                v-col
                    LaborBaseline
    v-dialog(v-model='editLaborSupDialog', max-width='50vw', scrollable)
        v-card(light)
            v-card-title Edit Supplemental Labor
            v-card-text
                v-form(ref='form')
                    v-row
                        v-col(cols='4')
                            v-text-field(label='Name', v-model='currentSup.name', outlined, color='brand')
                        v-col(cols='8')
                            v-text-field(label='Description', v-model='currentSup.description', outlined, color='brand')
                    v-row
                        v-col
                            v-checkbox(label='Flat Rate', v-model='currentSup.flat_rate', color='brand', title='If set, the same rate will be applied to all labor types.')
                        v-col
                            v-text-field(type='number', v-model.number='currentSup.rate', outlined, v-if='currentSup.flat_rate', suffix='%', color='brand')
                    v-row
                        v-col
                            v-list
                                v-subheader Labor to Mark Up
                                v-list-item(v-for='laborType in currentSup.laborTypes', :key='laborType.id', v-if='laborIncludedInSup(laborType)')
                                    v-list-item-action
                                        v-btn(icon, @click='removeLaborSupType(laborType)')
                                            v-icon remove
                                    v-list-item-content
                                        v-list-item-title {{ laborType.cost_item }}
                                        v-list-item-subtitle.wrap-text {{ getLaborDescription(laborType.cost_item) }}
                                    v-list-item-action(v-if='!currentSup.flat_rate')
                                        v-text-field.rateInput(type='number', v-model='laborType.rate', width='40', min='0', suffix='%')
                        v-col
                            v-list
                                v-subheader Labor Type Options
                                v-list-item(v-for='laborType in laborTypes', :key='laborType.id', v-if='!laborIncludedInSup(laborType)')
                                    v-list-item-action
                                        v-btn(icon, @click='selectLaborForSup(laborType)')
                                            v-icon add
                                    v-list-item-content
                                        v-list-item-title {{ laborType.cost_item }}
                                        v-list-item-subtitle.wrap-text {{ laborType.description }}
                        
            v-card-actions
                v-spacer
                v-btn(text, @click='editLaborSupDialog = false') Cancel
                v-btn.white--text(@click='updateSupLabor', color='green', :disabled='disableLaborSup') Save
            v-overlay(absolute, :value='busy')
                .text-h6 Please Wait...
                v-progress-linear(indeterminate)

    v-dialog(v-model='showNewRatesModal', max-width='50vw')
        v-card
            v-card-title Add new rates
            v-card-text
                v-row
                    v-col
                        span Select a state to copy rates from
                v-row
                    v-col
                        v-select(label='State', :items='statesWithLabor', v-model='stateToCopy')
                v-row
                    v-col
                        v-btn.white--text(@click='copyRates', color='green', :disabled='!stateToCopy') Copy

    v-dialog(v-model='displayOrderDialog', max-width='50vw', sortable)
        v-card(light)
            v-card-title Edit Display Order
            v-card-text
                v-row
                    v-col
                        v-list
                            v-list-item(v-for='(supLabor, index) of sortedSupLabor', :key='supLabor.id')
                                v-list-item-content
                                    v-list-item-title {{ supLabor.name }}
                                    v-list-item-subtitle {{ supLabor.description }}
                                v-list-item-action
                                    v-btn(icon, @click='moveSupItem(index, -1)')
                                        v-icon arrow_upward
                                    v-btn(icon, @click='moveSupItem(index, 1)')
                                        v-icon arrow_downward
            v-card-actions
                v-spacer
                v-btn(text, @click='stopOrderEditing', :loading='savingOrder') Cancel
                v-btn.white--text(:loading='savingOrder', @click='saveSupOrder', color='brand') Save
</template>

<script>
import {cloneDeep, merge} from 'lodash';
import SpecialParts from '@/components/salestool/SpecialParts.vue';
import LaborBaseline from '@/components/salestool/laborbaseline.vue';

export default {
    components: {
        SpecialParts,
        LaborBaseline
    },
    data () {
        return {
            dti,
            accessKey: 'satlabor',
            message: '',
            busy: false,

            laborRates: [],
            laborTypes: [],
            supplementalLabor: [],
            settings: {},
            overhead: 0,
            profit: 0,

            states: ['Alabama','Alaska','American Samoa','Arizona','Arkansas','California','Colorado','Connecticut','Delaware','District of Columbia','Federated States of Micronesia',
                'Florida','Georgia','Guam','Hawaii','Idaho','Illinois','Indiana','Iowa','Kansas','Kentucky','Louisiana','Maine','Marshall Islands','Maryland','Massachusetts',
                'Michigan','Minnesota','Mississippi','Missouri','Montana','Nebraska','Nevada','New Hampshire','New Jersey','New Mexico','New York','North Carolina','North Dakota',
                'Northern Mariana Islands','Ohio','Oklahoma','Oregon','Palau','Pennsylvania','Puerto Rico','Rhode Island','South Carolina','South Dakota','Tennessee','Texas','Utah',
                'Vermont','Virgin Island','Virginia','Washington','West Virginia','Wisconsin','Wyoming'
            ],
            selectedState: 'North Carolina', // defaults to NC
            stateToCopy: '',

            showNewRatesModal: false,
            showNewTypeModal: false,
            typeCostItem: '',
            typeName: '',
            typeDescription: '',
            tab: 0,
            editLaborSupDialog: false,
            currentSup: {
                name: '',
                description: '',
                flat_rate: false,
                rate: 50,
                id: '',
                laborTypes: []
            },
            rateHeaders: [
                {
                    text: 'Cost Item',
                    value: 'cost_item',
                    width: 110
                },
                {
                    text: 'Name',
                    value: 'name',
                    sortable: false
                },
                {
                    text: 'Tasks',
                    value: 'description',
                    sortable: false
                },
                {
                    text: 'Cost',
                    value: 'cost'
                },
                {
                    text: 'Selling Price',
                    value: 'selling_price',
                    width: 100,
                    sortable: false
                }
            ],
            supLaborHeaders: [
                {
                    text: 'Name',
                    value: 'name'
                },
                {
                    text: 'Description',
                    value: 'description'
                },
                {
                    text: 'Flat Rate',
                    value: 'flat_rate'
                },
                {
                    text: 'Rate',
                    value: 'rate'
                },
                {
                    text: 'Labor Types',
                    value: 'labor_types'
                },
                {
                    text: '',
                    value: 'edit'
                }
            ],
            displayOrderDialog: false,
            savingOrder: false
        };
    },
    methods: {
        getLaborData () {
            this.socketEmit('getLaborRates', null, (results) => {
                this.laborRates = results.laborRates;
                this.laborTypes = results.laborTypes;
                this.supplementalLabor = results.supplementalLabor;
                this.processLaborMarkup(results.settings);
            });
        },
        processLaborMarkup (settings) {
            this.settings = settings[0];
            this.overhead = this.settings.labor_overhead;
            this.profit = this.settings.labor_profit;
        },
        updateLaborRate (laborRate) {
            this.doUpdateLaborRate(laborRate);
        },
        async doUpdateLaborRate (laborRate) {
            let result = await this.sendCommand({
                action: 'updateLaborRate',
                parameters: laborRate
            });
            if (result) {
                this.message = `"${laborRate.name}" updated`;
            } else {
                console.log('error updating labor rate');
                this.message = `Error updating "${laborRate.name}"`;
            }
        },
        updateSettings () {
            this.settings.labor_overhead = this.overhead;
            this.settings.labor_profit = this.profit;
            this.doUpdateSettings(this.settings);
            this.message = `Labor markup updated successfully`;
        },
        async doUpdateSettings (settings) {
            let result = await this.sendCommand({
                action: 'updateSettings',
                parameters: settings
            });
            if (result) {
                this.message = `Labor markup updated successfully`;
            } else {
                console.log('error updating labor markup');
                this.message = `Error updating labor markup`;
            }
        },
        calculateSellingPrice (laborRate) {
            return laborRate.cost * (1 + this.overhead / 100) * (1 + this.profit / 100);
        },

        async copyRates () {
            let ratesToCopy = this.laborRates.filter(rate => rate.state === this.stateToCopy),
                newRates = [];
            ratesToCopy.forEach(rate => {
                let newRate = cloneDeep(rate);
                newRate.state = this.selectedState;
                newRate.id = dti.makeuuid();
                newRates.push(newRate);
            });
            let results = await this.sendCommand({
                action: 'addLaborRates',
                parameters: newRates
            });
            if (results) {
                this.message = `Added rates for ${this.selectedState}`;
                this.laborRates.push(...newRates);
                this.showNewRatesModal = false;
            } else {
                this.message = `Failed to add rates for ${this.selectedState}`;
            }
        },
        getLaborDescription (costItem) {
            return this.laborTypes.find(laborType => laborType.cost_item === costItem).description;
        },
        getLaborName (costItem) {
            return this.laborTypes.find(laborType => laborType.cost_item === costItem).name;
        },
        startAddNewLaborSup () {
            this.editLaborSupDialog = true;
        },
        async updateSupLabor () {
            if (!this.currentSup.id) {
                this.currentSup.id = dti.makeuuid();
            }
            this.currentSup.laborTypes.forEach(labor => {
                labor.supplemental_labor = this.currentSup.id;
            });
            let result = await this.sendCommand({
                action: 'updateSupLabor',
                parameters: this.currentSup
            });
            if (result) {
                this.message = `"${this.currentSup.name}" updated`;
                this.getLaborData();
            } else {
                console.log('error updating supplemental labor');
                this.message = `Error updating "${this.currentSup.name}"`;
            }
            this.editLaborSupDialog = false;
        },
        laborIncludedInSup (laborType) {
            let ret = false;
            this.currentSup.laborTypes.forEach(labType => {
                if (labType.cost_item === laborType.cost_item) {
                    ret = true;
                }
            });
            return ret;
        },
        selectLaborForSup (laborType) {
            this.currentSup.laborTypes.push({
                id: dti.makeuuid(),
                cost_item: laborType.cost_item,
                supplemental_labor: '',
                rate: 50
            });
        },
        removeLaborSupType (laborType) {
            let idx = this.currentSup.laborTypes.indexOf(laborType);
            this.currentSup.laborTypes.splice(idx, 1);
        },
        editSupLabor (laborSupData) {
            merge(this.currentSup, laborSupData);
            this.editLaborSupDialog = true;
        },
        showDisplayOrderDialog () {
            this.displayOrderDialog = true;
        },
        moveSupItem (index, direction) {
            if (direction === -1 && index === 0) {
                return;
            }
            if (direction === 1 && index === this.sortedSupLabor.length - 1) {
                return;
            }
            const items = cloneDeep(this.sortedSupLabor);
            const itemToMove = items.splice(index, 1)[0];
            items.splice(index + direction, 0, itemToMove);
            let displayOrder = 0;
            for (const item of items) {
                item.display_order = displayOrder;
                displayOrder++;
            }
            for (const supLabor of this.supplementalLabor) {
                supLabor.display_order = items.find(item => item.id === supLabor.id).display_order;
            }
        },
        stopOrderEditing () {
            this.displayOrderDialog = false;
            this.getLaborData();
        },
        async saveSupOrder () {
            this.savingOrder = true;
            for (const sup of this.supplementalLabor) {
                this.currentSup = sup;
                await this.updateSupLabor();
            }
            this.savingOrder = false;
            this.displayOrderDialog = false;
        }
    },
    computed: {
        showAlert: {
            get () {
                return this.message !== '';
            },
            set (val) {
                if (val === false) {
                    this.message = '';
                }
            }
        },
        filteredRates () {
            return this.laborRates.filter(rate => rate.state === this.selectedState);
        },
        allTypes () {
            return this.laborTypes;
        },
        statesWithLabor () {
            let states = [];
            this.laborRates.forEach(rate => {
                if (!states.includes(rate.state)) {
                    states.push(rate.state);
                }
            });
            return states;
        },
        disableLaborSup () {
            if (this.currentSup.name === '') {
                return true;
            }
            return false;
        },
        sortedSupLabor () {
            return [...this.supplementalLabor].sort((a, b) => {
                return a.display_order - b.display_order;
            });
        }
    },
    watch: {
        editLaborSupDialog (visible) {
            if (!visible) {
                this.$refs.form.reset();
                this.currentSup.id = '';
            }
        }
    },
    mounted () {
        this.getLaborData();
        window.satlaborvm = this;
    }
};
</script>

<style lang="scss" scoped>


</style>

<style lang="scss">
.laborRates {
    .laborTable {
        td {
            position: relative;
        }
        .costInput {
            width: 100px;
        }
    }
}
.wrap-text {
    white-space: normal;
}
.rateInput {
    width: 60px;
}
</style>
