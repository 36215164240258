<template lang="pug">
v-card.mb-12(light)
    v-card-text
        template(v-if='laborBaseline.length === 0')
            span.font-italic No labor added
        template(v-else)
            v-expansion-panels
                v-expansion-panel(v-for='systemType in systemsWithLaborBaseLine', :key='systemType.id', outlined)
                    v-expansion-panel-header {{ `${systemType.display} (${systemType.laborBaseline.filter(item => item.hours_per_point > 0).length} labor types defined)` }}
                    v-expansion-panel-content
                        v-simple-table(fixed-header, light)
                            template(v-slot:default)
                                thead
                                    tr
                                        th Cost Item
                                        th Description
                                        th Hours per Point
                                        th Interface Discount
                                        th Monitor-Only Discount
                                tbody
                                    tr(v-for='item in systemType.laborBaseline', v-if='item.hours_per_point > 0')
                                        td {{ item.cost_item }}
                                        td {{ getLaborDescription(item.cost_item) }}
                                        td {{ item.hours_per_point }}
                                        td {{ `${item.interface_discount}%`}}
                                        td {{ `${item.monitor_discount}%`}}
                        v-btn(@click='showEditDialog(systemType)') Edit

    v-card-actions
        v-spacer
        v-btn.white--text(color='green', @click='showEditDialog') Edit
    
    v-dialog(v-model='editDialog', max-width='60vw', scrollable)
        v-card
            v-card-title Baseline Labor
            v-card-text
                v-row
                    v-col
                        v-select.mt-1(:items='templateTypes', v-model='selectedSystemType', return-object, outlined, dense, label='System Type', item-text='display')
                v-row
                    v-col
                        v-form(ref='form', v-model='formIsValid')
                            v-data-table(:headers='columns', :items='selectedLaborBaseline', :items-per-page='-1')
                                template(v-slot:item.hours_per_point='{ item }')
                                    v-text-field(v-model.number='item.hours_per_point', hide-details, dense, outlined, type='number', :rules='noNegativeRule', :min='0', :max='100')
                                template(v-slot:item.interface_discount='{ item }')
                                    v-text-field(type='number', suffix='%', v-model.number='item.interface_discount', outlined, dense, hide-details, :rules='percentageRules', :min='0', :max='100')
                                template(v-slot:item.monitor_discount='{ item }')
                                    v-text-field(type='number', suffix='%', outlined, dense, hide-details, v-model.number='item.monitor_discount', :rules='percentageRules', :min='0', :max='100')
                                template(v-slot:item.labor_description='{ item }')
                                    span {{ getLaborDescription(item.cost_item) }}
            v-card-actions
                v-spacer
                v-btn(text, @click='closeDialog') Close
                v-btn(color='brand', @click='saveLaborBaseline', :loading='saving', :disabled='!formIsValid') Save


</template>

<script>

import {cloneDeep} from 'lodash';
import dti from 'dti';

const TEMPLATES = {
    laborBaseline: {
        system_type: null,
        cost_item: null,
        hours_per_point: 0,
        interface_discount: 0,
        monitor_discount: 0,
        id: null
    }
};


export default {
    data () {
        return {
            dti,
            accessKey: 'satlabor',
            message: '',
            busy: false,

            editDialog: false,
            saving: false,
            
            laborBaseline: [],
            templateData: [],
            templateTypes: [],
            laborTypes: [],
            editingLaborBaseline: [],
            selectedSystemType: null,
            selectedLaborBaseline: [],

            formIsValid: true,
            percentageRules: [
                v => v <= 100 || 'Value must be less than 100',
                v => v >= 0 || 'Value must be greater than 0'
            ],
            noNegativeRule: [
                v => v >=0 || 'Value must be greater than 0'
            ],

            columns: [
                {
                    text: 'Cost Item',
                    value: 'cost_item'
                },
                {
                    text: 'Description',
                    value: 'labor_description'

                },
                {
                    text: 'Hours/Point',
                    value: 'hours_per_point'
                },
                {
                    text: 'Interface Discount',
                    value: 'interface_discount'
                },
                {
                    text: 'Monitor-Only Discount',
                    value: 'monitor_discount'
                },
            ]
        };
    },
    computed: {
        systemsWithLaborBaseLine () {
            const systemTypes = cloneDeep(this.templateTypes);
            for (const systemTypeData of systemTypes) {
                systemTypeData.laborBaseline = this.laborBaseline.filter(item => item.system_type === systemTypeData.id);
            }
            return systemTypes;
        }
    },
    methods: {
        async saveLaborBaseline () {
            this.saving = true;
            let result = await this.sendCommand({
                action: 'saveLaborBaseline',
                parameters: this.selectedLaborBaseline
            });
            this.saving = false;
            this.closeDialog();
            this.getAllData();
        },
        getAllData () {
            this.socketEmit('getLaborRates', null, (results) => {
                this.laborBaseline = results.laborBaseline;
                this.laborTypes = results.laborTypes;
            });
            this.socketEmit('getTemplateData', null, (results) => {
                this.templateData = results.templates;
                this.templateTypes = results.templateTypes;
                this.templateTypes.push({display: 'Default', id: null});
            });
        },
        showEditDialog (systemType) {
            if (systemType) {
                this.selectedSystemType = systemType;
            }
            this.editDialog = true;
        },
        getTemplate (templateType) {
            return cloneDeep(TEMPLATES[templateType]);
        },
        closeDialog () {
            this.editDialog = false;
            this.selectedLaborBaseline = [];
            this.selectedSystemType = null;
        },
        getLaborDescription (costItem) {
            const laborType = this.laborTypes.find(item => item.cost_item === costItem);
            return laborType?.description || '';
        }
    },
    watch : {
        selectedSystemType (selectedType) {
            if (selectedType?.id || selectedType.display === 'Default') {
                this.selectedLaborBaseline = this.laborBaseline.filter(item => item.system_type === selectedType.id);
                for (const laborType of this.laborTypes) {
                    const exists = Boolean(this.selectedLaborBaseline.find(item => item.cost_item === laborType.cost_item));
                    if (!exists) {
                        const newBaseline = this.getTemplate('laborBaseline');
                        newBaseline.cost_item = laborType.cost_item;
                        newBaseline.system_type = selectedType.id;
                        newBaseline.id = dti.makeuuid();
                        this.selectedLaborBaseline.push(newBaseline);
                    }
                }
                this.$nextTick(() => {
                    this.$refs.form.validate();
                });
            }
        }
    },
    mounted () {
        this.getAllData();
        window.laborbaselinevm = this;
    }
};
</script>
