<template lang="pug">
    v-card.mb-12(light)
        v-card-text
            v-data-table.hoverable(:headers='headers', :items='specialParts', sort-by='name',  @click:row='handleClick')
        v-card-actions
            v-spacer
            v-btn.white--text(color='green', @click='startAddNew') Add New
        v-dialog(v-model='newSpecialDialog', width='50vw')
            v-card
                v-card-title Add New
                v-card-text
                    v-form(ref='specialForm')
                        v-row
                            v-col(cols='3')
                                v-autocomplete(outlined, v-model='currentSpecial.product_id', :items='searchResults', :loading='isLoading', :search-input.sync='searchStr', item-text='product_id', item_value='product_id', label='Product ID', cache-items)
                            v-col(cols='3')
                                v-select(v-model='currentSpecial.cost_item', :items='laborTypes', item-text='cost_item', item-value='const_item', outlined, label='Labor Type')
                            v-col(cols='3')
                                v-text-field(v-model.number='currentSpecial.labor_multiplier', min='0', outlined, label='Labor Multiplier', type='number')

                v-card-actions
                    v-spacer
                    v-btn(text, @click='stopAddNew') Cancel
                    v-btn(text, color='red', @click='deleteSpecial') Delete
                    v-btn(color='green', @click='saveSpecial', :disabled='saveDisabled') Save

</template>

<script>

import _ from 'lodash';

export default {
    data () {
        return {
            dti,
            accessKey: 'satlabor',
            message: '',
            busy: false,
            
            newSpecialDialog: false,
            currentSpecial: {
                id: dti.makeuuid(),
                product_id: '',
                labor_multiplier: 2,
                active: 1
            },
            searchStr: '',
            searchResults: [],
            isLoading: false,
            laborTypes: [],
            specialParts: [],
            showDelete: false
        };
    },
    computed: {
        headers () {
            return [
                {
                    text: 'Product ID',
                    value: 'product_id'
                },
                {
                    text: 'Labor Type',
                    value: 'cost_item'
                },
                {
                    text: 'Multiplier',
                    value: 'labor_multiplier'
                }
            ];
        },
        rows () {
            return [];
        },
        saveDisabled () {
            let ret = false;
            if (!this.currentSpecial.product_id || !this.currentSpecial.cost_item) {
                ret = true;
            }
            return ret;
        }
    },
    methods: {
        processSpecialPartsData ({ specialParts}) {
            this.specialParts = specialParts.filter(part => part.active);
        },
        startAddNew () {
            this.newSpecialDialog = true;
        },
        stopAddNew () {
            this.newSpecialDialog = false;
            this.resetForm();
            this.showDelete = false;
        },
        resetForm () {
            this.$refs['specialForm'].reset();
            this.currentSpecial.labor_multiplier = 2;
        },
        async saveSpecial () {
            let result = await this.sendCommand({
                action: 'updateSpecialPart',
                parameters: this.currentSpecial
            });
            this.getAllData();
            this.stopAddNew();
        },
        async deleteSpecial () {
            let data = _.cloneDeep(this.currentSpecial);
            data.active = false;
            let result = await this.sendCommand({
                action: 'updateSpecialPart',
                parameters: data
            });
            this.getAllData();
            this.stopAddNew();
        },
        laborIncludedInSpecial (laborType) {
            let ret = false;
            this.currentSpecial.laborTypes.forEach(labType => {
                if (labType.cost_item === laborType.cost_item) {
                    ret = true;
                }
            });
            return ret;
        },
        selectLaborForSpecial (laborType) {
            this.currentSpecial.laborTypes.push({
                id: dti.makeuuid(),
                cost_item: laborType.cost_item
            });
        },
        removeLaborSpecial (laborType) {
            let idx = this.currentSpecial.laborTypes.indexOf(laborType);
            this.currentSpecial.laborTypes.splice(idx, 1);
        },
        getLaborDescription (costItem) {
            return this.laborTypes.find(laborType => laborType.cost_item === costItem).description;
        },
        getAllData () {
            this.socketEmit('getLaborRates', null, (results) => {
                this.laborTypes = results.laborTypes;
            });
            this.socketEmit('getSpecialPartsData', null, (results) => {
                this.processSpecialPartsData(results);
            });
        },
        handleClick (rowData) {
            this.showDelete = true;
            this.searchStr = rowData.product_id;
            Object.assign(this.currentSpecial, rowData);
            this.startAddNew();
        }
    },
    watch : {
        searchStr (val) {
            this.isLoading = true;
            let vm = this;
            this.socketEmit('searchProductId', val, (results) => {
                vm.searchResults = results;
                vm.isLoading = false;
            });
        }

    },
    mounted () {
        this.getAllData();
    }
};
</script>
