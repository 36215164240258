import { render, staticRenderFns } from "./labor.vue?vue&type=template&id=344f945c&scoped=true&lang=pug"
import script from "./labor.vue?vue&type=script&lang=js"
export * from "./labor.vue?vue&type=script&lang=js"
import style1 from "./labor.vue?vue&type=style&index=1&id=344f945c&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "344f945c",
  null
  
)

export default component.exports